import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import WebriQForm from "../components/form/form"

class IndexPage extends React.Component {
  render() {
    const siteTitle = this.props.data.site.siteMetadata.title
    const siteDescription = this.props.data.site.siteMetadata.description
    // const posts = this.props.data.allSanityPost.edges
    // console.log(posts)
    return (
      <Layout location={this.props.location} title={siteTitle}>
        <SEO
          title="Environmental & Inspection Equipment"
          description={siteDescription}
        />
        <div className="slider">
          <div className="slide1 slides"></div>
          <div className="slide2 slides"></div>
          <div className="slide3 slides"></div>
          <div className="slide4 slides"></div>
          <div className="slide5 slides"></div>
          <div className="container h-100">
            <div className="row brandstamp">
              <div className="col-md-7">
                <h1>
                  <strong>
                    Experts in Environmental &amp; Inspection Equpment Solutions
                  </strong>
                </h1>
                <h4 className="mb-5 mt-0 accent-clr">
                  <strong>
                    We use our vast industry to provide top quality products
                    that match each customer's needs.
                  </strong>
                </h4>
                <Link
                  className="btn-global hvr-bounce-to-right btn-invert hvr-icon-buzz main-cta"
                  to="/contact-us"
                >
                  <i
                    className="fa fa-phone fa-lg mr-2 hvr-icon hvr-icon"
                    aria-hidden="true"
                  ></i>
                  Call Us Today
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid secondary-bg">
          <div className="container first-section text-white">
            <div className="triangle">
              <i
                className="fa fa-sort-desc secondary-clr fa-sort-down"
                aria-hidden="true"
              ></i>
            </div>
            <div className="row">
              <div className="col-md-7 padding-100">
                <h3 className="mt-0 p-0">
                  <strong>
                    The equipment we sell is top quality and our support is the
                    best in the region.
                  </strong>
                  <br />
                </h3>
              </div>
              <div className="col-md-5 contact-clean">
                {/* <WebriQForm
                  method="post"
                  form_id="0674b810-573b-4413-9a26-87402d9866c5"
                  form_name="Newsletter Form"
                  data-thankyou-url="/thank-you"
                >
                  <h2 className="text-center secondary-clr">
                    Contact us today
                  </h2>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="Name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="Email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="Message"
                      placeholder="Message"
                      rows="14"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group">
                    <a
                      className="btn-global hvr-bounce-to-right hvr-icon-buzz w-100 text-center"
                      href="/"
                    >
                      <i className="fa fa-envelope mr-2 hvr-icon hvr-icon"></i>
                      Send Message
                    </a>
                  </div>
                </WebriQForm> */}
                <WebriQForm
                  method="post"
                  form_name="Contact Us"
                  form_id="0674b810-573b-4413-9a26-87402d9866c5"
                  className="contact-us"
                  data-thankyou-url="/thank-you"
                >
                  <h2 className="text-center secondary-clr">
                    Contact us today
                  </h2>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="text"
                      name="Name"
                      placeholder="Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      className="form-control"
                      type="email"
                      name="Email"
                      placeholder="Email"
                    />
                  </div>
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="Message"
                      placeholder="Message"
                      rows="14"
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <div className="webriq-recaptcha" />
                  </div>
                  <div className="form-group">
                    {/* <a
                      className="btn-global hvr-bounce-to-right hvr-icon-buzz w-100 text-center"
                      href="/"
                    >
                      <i className="fa fa-envelope mr-2 hvr-icon hvr-icon"></i>
                      Send Message
                    </a> */}
                    <button
                      className="btn-global hvr-bounce-to-right hvr-icon-buzz text-center w-100"
                      type="submit"
                    >
                      send MESSAGE
                    </button>
                  </div>
                </WebriQForm>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-clean"></div>

        <div className="container-fluid padding-200 about-section">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-md-6">
                <img
                  className="w-100 about-img"
                  src={require("../images/900 ECO Combo 1.png")}
                  alt="men with the truck"
                />
              </div>
              <div className="col-md-6">
                <div className="about-content">
                  <h4 className="mt-0 mb-0 tetiary-clr">ABOUT US</h4>
                  <h2 className="mt-0">DAWSON INFRASTRUCTURE SOLUTIONS</h2>
                  <p>
                    Dawson Infrastructure Solutions is your sales, service,
                    parts and rental source for environmental&nbsp;and
                    inspection equipment, in support of pipeline video
                    assessment, hydro-excavation, sewer&nbsp;cleaning, leak
                    monitoring, rehabilitation and other specialized utility
                    needs.&nbsp;
                  </p>
                  {/*<Link
                    className="btn-global hvr-bounce-to-right hvr-icon-buzz text-center"
                    to="/"
                  >
                    Read More
                  </Link>*/}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/*<div className="container-fluid svc-service bg-dark">
          <div className="container">
            <div className="row svc-row">
              <div className="col-md-12">
                <h4 className="text-center mt-0 mb-0 tetiary-clr">
                  OUR SERVICES
                </h4>
                <h2 className="text-center text-white mt-0 mb-4">WHAT WE DO</h2>
              </div>
              <div className="col-md-4">
                <div className="svc-container">
                  <div className="svc-img-1 shadow">
                    <div className="triangle-up"></div>
                  </div>
                  <div className="svc-content p-5 shadow">
                    <h3 className="mt-0 mb-2">Sewer Equipment</h3>
                    <h5 className="mb-3 mt-0 secondary-clr">
                      <strong>(SEWEREQUIPMENT.COM)</strong>
                    </h5>
                    <p>
                      Sewer Equipment Co. of America equipment lines includes
                      high pressure, high volume truck and trailer-mounted.
                    </p>
                    <a
                      className="btn-global hvr-bounce-to-right"
                      href="https://www.sewerequipment.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="svc-container">
                  <div className="svc-img-2 shadow">
                    <div className="triangle-up"></div>
                  </div>
                  <div className="svc-content p-5 shadow">
                    <h3 className="mt-0 mb-2">IBAK</h3>
                    <h5 className="mb-3 mt-0 secondary-clr">
                      <strong>(RAPIDVIEW.COM)</strong>
                    </h5>
                    <p>
                      RapidView, LLC created business partnerships with several
                      manufacturers of high quality sewer pipeline maintenance
                      products.
                    </p>
                    <a
                      className="btn-global hvr-bounce-to-right"
                      href="http://rapidview.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="svc-container">
                  <div className="svc-img-3 shadow">
                    <div className="triangle-up"></div>
                  </div>
                  <div className="svc-content p-5 shadow">
                    <h3 className="mt-0 mb-2">IT PIPES</h3>
                    <h5 className="mb-3 mt-0 secondary-clr">
                      <strong>(ITPIPES.COM)</strong>
                    </h5>
                    <p>
                      ITpipes provides powerful software solutions and
                      unparalleled support for clients to proactively manage
                      their water and wastewater systems.
                    </p>
                    <a
                      className="btn-global hvr-bounce-to-right"
                      href="https://itpipes.com/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>*/}
        <div className="container-fluid vendor-section padding-50">
          <div className="container">
            <div className="row my-5 py-5 vendor-wrap">
              <div className="col-md-12 vertical-align">
                <h2 className="mt-0">OUR VENDORS</h2>
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 vertical-align">
                <img
                  className="w-100"
                  src="assets/img/vendor-1.jpg"
                  alt="Rapid View"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                <img
                  className="w-100"
                  src="assets/img/vendor-2.jpg"
                  alt="Sewer Equipment"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                <img
                  className="w-100"
                  src="assets/img/vendor-3.jpg"
                  alt="IT pipes"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                <img
                  className="w-100"
                  src="assets/img/vendor-4.jpg"
                  alt="Mongoose jetters"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                <img
                  className="w-100"
                  src="assets/img/vendor-5.jpg"
                  alt="USB-USA"
                />
              </div>
              <div className="col-lg-2 col-md-4 col-sm-6 col-12">
                <img
                  className="w-100"
                  src="assets/img/vendor-6.jpg"
                  alt="RAM VAC"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid m-0 p-0 map-section">
          <div className="row">
            <div className="col-md-6 p-0 map-1">
              <iframe
                title="Denver Co"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d392371.01456144423!2d-104.98119!3d39.798051!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x876c79b5c07bd1a1%3A0x13d7072eb8d77e81!2s434%20E%2056th%20Ave%2C%20Denver%2C%20CO%2080216!5e0!3m2!1sen!2sus!4v1587189331302!5m2!1sen!2sus"
                width="100%"
                height="450"
                frameborder="0"
                style={{ border: "0" }}
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
              <div className="map-overlay-1 vertical-align">
                <h2 className="text-white">DENVER, CO</h2>
              </div>
            </div>
            <div className="col-md-6 p-0 map-2">
              <iframe
                title="Salt Lake City UT"
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d386779.0527004605!2d-111.943156!3d40.768373!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8752f47b0985cdbf%3A0x607961e728fb6fa0!2s47%20Orange%20St%2C%20Salt%20Lake%20City%2C%20UT%2084116!5e0!3m2!1sen!2sus!4v1587189474354!5m2!1sen!2sus"
                width="100%"
                height="450"
                frameborder="0"
                style={{ border: "0" }}
                allowfullscreen=""
                aria-hidden="false"
              ></iframe>
              <div className="map-overlay-2 vertical-align">
                <h2 className="text-white">SALT LAKE CITY, UT</h2>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage

export const indexPageQuery = graphql`
  query indexPageQuery {
    site {
      siteMetadata {
        title
        author
        description
      }
    }
  }
`
